html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.form-control:focus {
  border-color: #8e00b2 !important;
  box-shadow: 0 0 0 0.2rem rgba(142, 0, 178, 0.25) !important;
}
