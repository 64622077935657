.DayPicker {
  width: 90%;
  font-size: 19px;
}
.DayPicker:focus,
.DayPicker-wrapper:focus,
.DayPicker-Months:focus,
.DayPicker-Month:focus,
.DayPicker-Day:focus,
.DayPicker-NavButton:focus {
  outline: none;
}
.DayPicker-Day {
  font-weight: 200;
  color: #8e00b2;
  border-radius: 50%;
}

.DayPicker-Day--disabled {
  color: rgb(177, 177, 179);
}

.DayPicker-Day--disabled:focus {
  -webkit-box-shadow: 0px 0px 5px #dfdfdf;
  box-shadow: 0px 0px 5px #dfdfdf;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #8e00b2;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background: #8e00b2;
}

#customScroll::-webkit-scrollbar {
  width: 10px;
}

#customScroll::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

#customScroll::-webkit-scrollbar-thumb {
  height: 180px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  background-color: white;
}

.spinner-container:last-child {
  opacity: 0.3;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #8e00b2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
}

.page {
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  -ms-animation: fadein 1s;
  -o-animation: fadein 1s;
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#content-botoes-calendar {
  padding-left: 70px;

  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

@-webkit-keyframes slide {
  100% {
    padding-left: 0;
  }
}

@keyframes slide {
  100% {
    padding-left: 0;
  }
}
